@tailwind base;
@layer base {
  :root {
    --ci-primary: #ff0000;
    --gradient-primary-1: #ea0000;
    --gradient-primary-2: #750000;
    --gradient-secondary-1: #ff0000;
    --gradient-secondary-2: #cd0000;
    /* ... */
  }
  body {
    @apply bg-gray-f5;
    font-family: var(--DBHeavent), -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
      Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif !important;
  }
  h1 {
    @apply text-2xl;
  }
  h2 {
    @apply text-xl;
  }
  h3 {
    @apply text-lg;
  }
  .min-w-max {
    min-width: max-content;
  }
  .min-w-max-mb {
    min-width: 100%;
  }
  @media (min-width: 1024px) {
    .min-w-max-mb {
      min-width: max-content;
    }
  }

  .min-w-max-footer {
    min-width: 100%;
  }
  @media (min-width: 640px) {
    .min-w-max-footer {
      min-width: max-content;
    }
  }
  .container-xl {
    @apply mx-auto container px-2 sm:px-6 lg:px-8;
  }
  .bg-gradient-primary {
    background: transparent
      linear-gradient(180deg, var(--gradient-primary-1) 0%, var(--gradient-primary-2) 100%) 0% 0%
      no-repeat padding-box !important;
  }
  .bg-gradient-secondary {
    background: transparent
      linear-gradient(180deg, var(--gradient-secondary-1) 0%, var(--gradient-secondary-2) 100%) 0%
      0% no-repeat padding-box !important;
  }
}
.ant-form-item-with-help .ant-form-item-explain {
  padding-top: 0.3125rem;
}
.ant-form-item .ant-form-item-explain-error {
  @apply text-primary;
  font-size: 1.125rem;
  line-height: 1.375rem;
}

@tailwind components;
@layer components {
}

@tailwind utilities;

.text-light {
  font-family: var(--DBHeavent);
  font-weight: 300;
}
.text-reg {
  font-family: var(--DBHeavent);
  font-weight: 400;
}
.text-med {
  font-family: var(--DBHeavent);
  font-weight: 500;
}
.text-bold {
  font-family: var(--DBHeavent);
  font-weight: 700;
}

/* CutomSwipper */

.swiper-button-prev {
  border-radius: 50% !important;
  width: 2rem !important;
  height: 2rem !important;
  background: #ffffff !important;
  box-shadow: 0px 3px 6px #00000029 !important;
}
.swiper-button-prev::after {
  font-family: swiper-icons !important;
  font-size: 1rem !important;
  text-transform: none !important;
  letter-spacing: 0 !important;
  font-variant: initial !important;
  line-height: 1 !important;
  color: #5a5a5a !important;
}
.swiper-button-prev:focus:after {
  color: #ffffff !important;
}
.swiper-button-prev:focus {
  background: transparent
    linear-gradient(180deg, var(--gradient-primary-1) 0%, var(--gradient-primary-2) 100%) 0% 0%
    no-repeat padding-box !important;
}
.swiper-button-prev:hover:after {
  color: #ffffff !important;
}
.swiper-button-prev:hover {
  background: transparent
    linear-gradient(180deg, var(--gradient-primary-1) 0%, var(--gradient-primary-2) 100%) 0% 0%
    no-repeat padding-box !important;
}
.swiper-button-prev.swiper-button-disabled {
  opacity: 1 !important;
  background: #ffffff !important;
}

.swiper-button-prev[aria-disabled='true']::after {
  color: #5a5a5a !important;
}
.swiper-button-prev[aria-disabled='false']::after {
  color: #ffffff !important;
}
/* next */
.swiper-button-next {
  border-radius: 50% !important;
  width: 2rem !important;
  height: 2rem !important;
  background: #ffffff !important;
  box-shadow: 0px 3px 6px #00000029 !important;
}
.swiper-button-next::after {
  font-family: swiper-icons !important;
  font-size: 1rem !important;
  text-transform: none !important;
  letter-spacing: 0 !important;
  font-variant: initial !important;
  line-height: 1 !important;
  color: #5a5a5a !important;
}
.swiper-button-next:focus:after {
  color: #ffffff !important;
}
.swiper-button-next:focus {
  background: transparent
    linear-gradient(180deg, var(--gradient-primary-1) 0%, var(--gradient-primary-2) 100%) 0% 0%
    no-repeat padding-box !important;
}
.swiper-button-next:hover:after {
  color: #ffffff !important;
}
.swiper-button-next:hover {
  background: transparent
    linear-gradient(180deg, var(--gradient-primary-1) 0%, var(--gradient-primary-2) 100%) 0% 0%
    no-repeat padding-box !important;
}
.swiper-button-next.swiper-button-disabled {
  opacity: 1;
  background: #ffffff !important;
}

.swiper-button-next[aria-disabled='true']::after {
  color: #5a5a5a !important;
}
.swiper-button-next[aria-disabled='false']::after {
  color: #ffffff !important;
}

/* Scroolbar */
/* width */
::-webkit-scrollbar {
  width: 2px;
  height: 2px;
}
::-moz-scrollbar {
  width: 2px;
  height: 2px;
}
::-ms-scrollbar {
  width: 2px;
  height: 2px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}
::-moz-scrollbar-track {
  background: transparent;
}
::-ms-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.3);
}
::-moz-scrollbar-thumb {
  background: transparent;
}
::-ms-scrollbar-thumb {
  background: transparent;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #9a9a9a;
}
::-moz-scrollbar-thumb:hover {
  background: #9a9a9a;
}
::-ms-scrollbar-thumb:hover {
  background: #9a9a9a;
}

/* Text Header Component */
.text-heder-component {
  @apply text-dark-gray;
  font-size: 1.875rem;
  line-height: 1.875rem;
  font-weight: 500;
  margin-bottom: 0 !important;
}
@media (min-width: 640px) {
  .text-heder-component {
    font-size: 2.25rem;
    line-height: 2.25rem;
  }
}
.underline-text {
  display: inline-block;
  width: 7.125rem;
  height: 0.375rem;
  background: transparent linear-gradient(180deg, #ff0000 0%, #cd0000 100%) 0% 0% no-repeat
    padding-box;
}
.text-see-all {
  @apply text-dark-gray pr-2;
  font-weight: 400;
  font-size: 1.25rem;
  line-height: 1.25rem;
}
.icon-see-all {
  @apply flex items-center justify-center bg-primary text-white;
  width: 0.875rem;
  height: 0.875rem;
  border-radius: 50%;
}

.swiper-pagination-bullet {
  background: #3b301c !important;
  opacity: 0.5 !important;
  width: 0.75rem !important;
  height: 0.75rem !important;
}
.swiper-pagination-bullet-active {
  background-color: #333333 !important;
  opacity: 1 !important;
}
.cm-swiper-dot {
  padding-bottom: 0 !important;
}
.cm-swiper-dot .swiper-pagination {
  display: none !important;
}
@media (min-width: 640px) {
  .cm-swiper-dot {
    padding-bottom: 2rem !important;
  }
  .cm-swiper-dot .swiper-pagination {
    display: inline-block !important;
  }
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: transparent !important;
  border-color: transparent !important;
  background: transparent linear-gradient(180deg, #ea0000 0%, #750000 100%) 0% 0% no-repeat
    padding-box !important;
}
.ant-pagination-next,
.ant-pagination-prev {
  background-color: #ffffff !important;
  border: 1px solid #e3e3e3;
}

.ant-pagination-next .ant-pagination-item-link,
.ant-pagination-prev .ant-pagination-item-link {
  font-size: 1.125rem;
  line-height: 1.125rem;
}
.ant-pagination .ant-pagination-item {
  background-color: #ffffff !important;
  border-color: #e3e3e3;
}
.ant-pagination .ant-pagination-item a {
  color: #333333 !important;
  font-size: 1.125rem;
  font-weight: 400;
  /* line-height: 1.125rem; */
}
.ant-pagination .ant-pagination-item-active {
  border-color: #333333 !important;
  background-color: #333333 !important;
}
.ant-pagination .ant-pagination-item-active a {
  color: #ffffff !important;
  font-size: 1.125rem;
  font-weight: 400;
}

.nav-breadcrumb {
  @apply flex  w-full items-center pb-4 px-2 sm:pb-6 sm:px-0;
}
.nav-breadcrumb .text-span {
  @apply text-dark-gray;
  font-size: 1.125rem;
  line-height: 1.125rem;
  font-weight: 400;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.nav-breadcrumb .link-item {
  min-width: max-content;
}
/* .nav-breadcrumb .text-span::after {
  content: '/';
  padding: 0 0.3125rem;
} */
.nav-breadcrumb .link-item {
  @apply text-gray-9a hover:text-primary focus:text-primary;
  font-size: 1.125rem;
  line-height: 1.125rem;
  font-weight: 400;
}
.nav-breadcrumb .link-item::after {
  @apply text-gray-9a;
  content: '/';
  padding: 0 0.3125rem;
}

.header-title-component {
  @apply flex flex-row gap-3 justify-between items-center;
  border-bottom: 1px solid #e5e7eb;
}
.header-title-component-col {
  @apply flex flex-col gap-3 justify-start items-start sm:flex-row sm:justify-between sm:items-center;
  border-bottom: 0;
}
.text-header-left {
  @apply text-dark-gray;
  font-size: 1.875rem;
  line-height: 1.875rem;
  font-weight: 500;
}
.border-bottom-head-mb {
  border-bottom: 1px solid #e5e7eb;
}
@media (min-width: 640px) {
  .border-bottom-head-mb {
    border-bottom: 0;
  }
  .header-title-component-col {
    border-bottom: 1px solid #e5e7eb;
  }
  .text-header-left {
    @apply text-dark-gray;
    font-size: 2.25rem;
    line-height: 2.25rem;
    font-weight: 500;
  }
}
.img-container-1-1 {
  position: relative;
  width: 100%;
  padding-top: 100%; /* 1:1 Aspect Ratio */
  border-radius: 0.625rem;
}
.img-container-1-1 .ant-image {
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  border-radius: 0.625rem;
}
.image-ratio-box-inside {
  border-radius: 0.625rem;
  position: absolute;
  top: 0;
  height: 100% !important;
  object-fit: cover !important;
  vertical-align: top !important;
  object-position: center !important;
}
.cm-steps .ant-steps-item-finish .ant-steps-item-icon {
  background-color: #ff0000 !important;
  background: transparent
    linear-gradient(180deg, var(--gradient-primary-1) 0%, var(--gradient-primary-2) 100%) 0% 0%
    no-repeat padding-box !important;
  opacity: 0.5 !important;
  border-color: transparent !important;
}
.cm-steps .ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
  color: #ffffff !important;
}
.cm-steps .ant-steps-item-process .ant-steps-item-icon {
  background-color: #ff0000 !important;
  background: transparent
    linear-gradient(180deg, var(--gradient-primary-1) 0%, var(--gradient-primary-2) 100%) 0% 0%
    no-repeat padding-box !important;
  opacity: 1 !important;
  border-color: transparent !important;
}
.cm-steps .ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon {
  font-size: 1.5rem !important;
  line-height: 1.5rem !important;
}
.cm-steps .ant-steps-item-wait .ant-steps-item-icon {
  @apply bg-gray-e3 !important;
  border-color: transparent !important;
}
.cm-steps .ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon {
  @apply text-dark-gray !important;
  font-size: 1.5rem !important;
  line-height: 1.5rem !important;
}
.cm-steps .ant-steps-item-title::after {
  top: 0.75rem !important;
  background-color: #b20000 !important;
}
.cm-steps
  .ant-steps-item-finish
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title::after {
  top: 0.75rem !important;
  background-color: #b20000 !important;
}
.cm-steps .ant-steps-item-title {
  @apply text-24 leading-24 text-dark-gray font-normal !important;
}
.cm-steps .ant-steps-item-description {
  @apply text-24 leading-24 text-gray-9a font-normal !important;
}
.cm-steps
  .ant-steps-item-process
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-description {
  @apply text-gray-9a !important;
}
.cm-steps
  .ant-steps-item-wait
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title {
  @apply text-dark-gray !important;
}

.text-header-order {
  @apply text-dark-gray;
  margin-bottom: 0 !important;
  font-size: 1.5rem;
  line-height: 1.5rem;
  font-weight: 500;
}
.wrap-card-order-component {
  @apply flex flex-col bg-white px-4 py-6;
  border-radius: 0.625rem;
  border: 1px solid #e3e3e3;
}
.wrap-card-order {
  @apply flex flex-col bg-white p-4;
  border-radius: 0.625rem;
  border: 1px solid #e3e3e3;
}
.row-header {
  @apply flex flex-row items-center gap-5 pb-3 sm:pb-3 pl-0 sm:pl-4 pr-0 sm:pr-4;
}
.row-header-card-order {
  @apply flex flex-row items-center gap-5 pb-3 sm:pb-3 pr-0 sm:pr-4;
}
.row-header-purchase {
  @apply flex flex-col sm:flex-row items-start sm:items-center gap-5 pb-3 sm:pb-3 pr-0 sm:pr-4;
  justify-content: space-between;
}
/* @media screen and (max-width: 390px) {
  .row-header-purchase {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
} */
.row-brand {
  @apply flex flex-row gap-3 items-center;
}
.row-brand img {
  width: 1.75rem;
}
.text-name {
  @apply text-dark-gray;
  font-size: 1.125rem;
  line-height: 1.125rem;
  font-weight: 500;
}
.text-price {
  @apply text-primary;
  font-size: 1.125rem;
  line-height: 1.125rem;
  font-weight: 500;
}
.box-header {
  @apply bg-dark-gray hidden sm:hidden md:hidden lg:flex xl:flex flex-row items-center gap-3;
  padding: 0.8125rem 1rem 0.8125rem 1rem;
  border-radius: 0.625rem;
}
.text-start-order {
  padding-left: 1.875rem;
}
/* .text-start-order {
  padding-left: 1.875rem;
} */
.box-header span {
  @apply text-white;
  font-size: 1.125rem;
  line-height: 1.125rem;
}
.w-50px,
.w-60px,
.w-110px,
.w-131px,
.w-108px,
.w-118px,
.w-150px,
.w-216px,
.w-80px,
.w-40px {
  min-width: auto;
  max-width: auto;
}

.w-last {
  min-width: 100%;
  max-width: 100%;
}
@media (min-width: 1024px) {
  .w-80px {
    min-width: 5rem;
    max-width: 5rem;
  }
  .w-50px {
    min-width: 3.125rem;
    max-width: 3.125rem;
  }
  .w-40px {
    min-width: 2.5rem;
    max-width: 2.5rem;
  }
  .w-60px {
    min-width: 3.75rem;
    max-width: 3.75rem;
  }
  .w-110px {
    min-width: 7.125rem;
    max-width: 7.125rem;
  }
  .w-131px {
    min-width: 8.1875rem;
    max-width: 8.1875rem;
  }
  .w-108px {
    min-width: 6.75rem;
    max-width: 6.75rem;
  }
  .w-118px {
    min-width: 7.375rem;
    max-width: 7.375rem;
  }
  .w-150px {
    min-width: 9.375rem;
    max-width: 9.375rem;
  }
  .w-200px {
    min-width: 12.5rem;
    max-width: 12.5rem;
  }
  .w-last {
    min-width: 9.375rem;
    max-width: 9.375rem;
  }
  .w-216px {
    min-width: 13.5rem;
    max-width: 13.5rem;
  }
}

.flex-header {
  @apply flex justify-center items-center px-0 sm:px-0 md:px-0 lg:px-4 xl:px-6;
}
.flex-header-last {
  @apply flex justify-center items-center  lg:pl-4 xl:pl-6;
}
@media (max-width: 1023.999px) {
  .flex-header-last {
    padding-left: 5.625rem;
  }
}

.card-item {
  @apply bg-gray-f5 gap-3  flex flex-col sm:flex-col md:flex-col lg:flex-row xl:flex-row items-start lg:items-center p-4;
  border-radius: 0.5rem;
}
.box-image {
  @apply block;
  max-width: 3.75rem;
  min-width: 3.75rem;
  max-height: 3.75rem;
  min-height: 3.75rem;
  border-radius: 0.375rem;
}
.card-item .text-name-product {
  @apply text-dark-gray;
  margin-bottom: 0 !important;
  font-size: 1.125rem;
  line-height: 1.5rem;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.box-option {
  @apply flex flex-wrap max-w-max  bg-gray-e3 gap-1;
  border-radius: 0.3125rem;
  padding: 0.625rem;
  margin-top: 0.1875rem;
}
.text-option-count {
  @apply text-dark-gray;
  font-size: 1.125rem;
  line-height: 1.125rem;
}
.text-option {
  @apply text-dark-gray;
  font-size: 0.75rem;
  line-height: 0.75rem;
}
.box-option .text-option::after {
  content: ',';
}
.text-option:last-child::after {
  content: '';
}
.flex-card-product .ant-checkbox-wrapper {
  @apply flex flex-row items-center;
}
.flex-card-product .ant-checkbox-wrapper .ant-checkbox {
  @apply mr-2;
}
.flex-card-product .ant-checkbox-wrapper span:not(.ant-checkbox) {
  @apply flex flex-row gap-3;
  padding-inline-start: 0px !important;
  padding-inline-end: 0px !important;
}
.btn-link-primary {
  @apply text-primary;
  border: 0 !important;
  box-shadow: none !important;
}
.btn-link-primary:hover {
  color: #ea3323 !important;
}
.btn-quantity {
  @apply text-dark-gray text-18 leading-18;
  @apply flex items-center justify-center;
  min-width: 1.5625rem;
  max-width: 1.5625rem;
  min-height: 1.5625rem;
  max-height: 1.5625rem;
  background-color: #ffffff !important;
}

.btn-quantity span svg {
  width: 0.625rem;
  fill: #333333;
  opacity: 1;
}
.btn-quantity:hover {
  color: #ffffff !important;
  border-color: #ff0000 !important;
  background-color: #ff0000 !important;
}

.btn-quantity:disabled,
.btn-quantity:hover:disabled {
  background-color: #ebebeb !important;
  border: none !important;
}
.btn-quantity:hover span svg {
  fill: #ffffff;
}
.btn-quantity:hover:disabled span svg {
  fill: #333333;
}
.input-text-center {
  @apply text-dark-gray;
  min-height: 1.5625rem;
  max-height: 1.5625rem;
  min-width: 3.75rem;
  max-width: 3.75rem;
  font-size: 1.125rem !important;
  line-height: 1.125rem !important;
  text-align: center !important;
}

.input-text-center .ant-input-number-input {
  height: 1.5625rem;
  text-align: center !important;
}
.input-text-center .ant-input-number-handler-wrap {
  opacity: 1 !important;
}

.input-text-center .ant-input-number-handler-wrap .ant-input-number-handler:first-child {
  position: absolute;
  left: 28px;
  /* background: yellow; */
  z-index: 10;
  width: 100%;
  height: 100%;
  border: 10px !important;
}
.input-text-center .ant-input-number-handler-wrap .ant-input-number-handler:last-child {
  position: absolute;
  left: -65px;
  /* background: red; */
  z-index: 10;
  width: 100%;
  height: 100%;
  border: 10px !important;
}
.input-text-center .ant-input-number-handler-wrap .ant-input-number-handler svg {
  display: none;
}
.input-text-center-full .ant-input-number-handler-wrap {
  display: none;
}
.input-text-center-full .ant-input-number-input-wrap .ant-input-number-input {
  text-align: center;
}
/* 
.input-text-center-full {
  @apply text-dark-gray;
  min-height: 1.5625rem;
  max-height: 1.5625rem;
  font-size: 1.125rem !important;
  line-height: 1.125rem !important;
  text-align: center !important;
}

.input-text-center-full .ant-input-number-input {
  height: 1.5625rem;
  text-align: center !important;
}
.input-text-center-full .ant-input-number-handler-wrap {
  opacity: 1 !important;
}
.input-text-center-full
  .ant-input-number-handler-wrap
  .ant-input-number-handler:first-child {
  position: absolute;
  left: 28px;
  background: yellow;
  z-index: 10;
  width: 100%;
  height: 100%;
  border: 10px !important;
}
.input-text-center-full
  .ant-input-number-handler-wrap
  .ant-input-number-handler:last-child {
  position: absolute;
  left: -65px;
  background: red;
  z-index: 10;
  width: 100%;
  height: 100%;
  border: 10px !important;
}
.input-text-center-full
  .ant-input-number-handler-wrap
  .ant-input-number-handler
  svg {
  display: none;
} */
.mb-0 {
  margin-bottom: 0 !important;
}
.cm-input-number {
  margin-bottom: 0 !important;
}
.btn-outline-primary {
  @apply flex justify-center  border-primary border-solid border-2 text-primary hover:text-white;
  background-color: #ffffff !important;
}
.btn-outline-primary:hover {
  background-color: var(--ci-primary) !important;
}
.btn-delete {
  @apply flex items-center cursor-pointer text-dark-gray text-18 leading-18;
}
.btn-delete:hover {
  @apply text-primary;
}
.btn-delete svg {
  @apply mr-2;
  width: 1.125rem;
}
.footer-order {
  /* bottom-4 */
  @apply z-20   w-full flex fixed  left-0;
  bottom: 7.5rem;
}
.inner-footer {
  @apply bg-white p-4 flex flex-row justify-between items-start md:items-center gap-3 md:gap-4;
  box-shadow: 0px 0px 16px #0000001a;
  border-radius: 0.625rem;
}
.box-amount {
  @apply pl-4 pr-4 md:pr-4 md:pl-4 flex flex-row items-end;
}
.box-amount:first-child {
  @apply pl-0 pr-2 md:pr-4 md:pl-4;
  border-right: 1px solid #333333;
}
.box-amount:last-child {
  @apply pl-2 pr-0 md:pr-4 md:pl-4;
}

/* Radio */
.ant-radio-wrapper .ant-radio-checked .ant-radio-inner {
  background: transparent linear-gradient(180deg, #ea0000 0%, #750000 100%) 0% 0% no-repeat
    padding-box !important;
  border: 0 !important;
}
.radio-btn {
  @apply gap-4 sm:gap-4 md:gap-5 lg:gap-7;
}
.box-radio .ant-radio {
  align-self: flex-start !important;
  padding-top: 0.3125rem !important;
}
.radio-btn .ant-radio-wrapper {
  width: 100%;
}
.radio-btn .ant-radio-wrapper:first-child {
  margin-right: 0 !important;
}
.radio-btn .ant-radio-wrapper {
  @apply p-4;
  border-radius: 0.625rem;
  border: 1px solid #e3e3e3;
}
.radio-btn .ant-radio-wrapper .text-header {
  font-size: 1.5rem;
  line-height: 1.5rem;
  color: #333333;
  font-size: 500;
}
.radio-btn .ant-radio-wrapper .text-title {
  font-size: 1.125rem;
  line-height: 1.125rem;
  color: #9a9a9a;
  font-size: 400;
}
.radio-btn .ant-radio-wrapper-checked {
  background: transparent linear-gradient(180deg, #ea0000 0%, #750000 100%) 0% 0% no-repeat
    padding-box;
  border: 0;
}
.radio-btn .ant-radio-wrapper-checked .text-header {
  color: #ffffff;
}
.radio-btn .ant-radio-wrapper-checked .text-title {
  color: #ffffff;
}
.radio-btn .ant-radio-wrapper .ant-radio-checked .ant-radio-inner {
  border: 1px solid #ffffff !important;
}

/* Modal */
.ant-modal-content .ant-modal-close-x {
  font-size: 1.5rem;
  line-height: 1.5rem;
}
.ant-modal-content .ant-modal-close-x svg path {
  fill: #707070;
}
.ant-modal .ant-modal-close:active {
  background-color: transparent;
}
.ant-modal .ant-modal-close:hover {
  background-color: transparent;
}

.ant-modal-content .ant-modal-footer .ant-btn-default {
  @apply text-primary order-2 sm:order-1;
  font-weight: 400;
  font-size: 1.375rem;
  line-height: 1.375rem;
  padding-top: 0.625rem;
  padding-bottom: 0.625rem;
  padding-left: 3rem;
  padding-right: 3rem;
  background-size: 300% 100%;
  -moz-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
  height: auto;
  border-radius: 1.875rem;
  background: #ffffff;
  border: 1px solid #ff0000;
}
.ant-modal-content .ant-modal-footer .ant-btn-default:hover {
  @apply bg-primary text-white;
  background-position: 100% 0%;
  -moz-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}
.ant-modal-content .ant-modal-footer .ant-btn + .ant-btn:not(.ant-dropdown-trigger) {
  margin-inline-start: 0 !important;
}
.ant-modal-content .ant-modal-footer .ant-btn-primary {
  @apply text-white order-1 sm:order-2;
  font-weight: 400;
  font-size: 1.375rem;
  line-height: 1.375rem;
  padding-top: 0.625rem;
  padding-bottom: 0.625rem;
  padding-left: 4rem;
  padding-right: 4rem;
  background-size: 300% 100%;
  -moz-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
  background-image: linear-gradient(
    to right,
    var(--gradient-primary-1),
    var(--gradient-primary-2),
    var(--gradient-primary-1),
    var(--gradient-primary-2)
  );
  box-shadow: 0px 0px 10px #d200006e;
  height: auto;
  border-radius: 1.875rem;
}
.ant-modal-content .ant-modal-footer .ant-btn-primary:hover {
  background-position: 100% 0%;
  -moz-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}
.ant-modal-root .ant-modal-wrap {
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
}

.container-spin {
  min-height: 100vh;
  min-width: 100vh;
  position: relative;
  z-index: 10;
}
.card-body-image {
  position: relative;
  display: flex;
  width: 100%;
}

.tag-product {
  @apply text-white;
  width: max-content;
  display: inline-block;
  background: transparent linear-gradient(90deg, #ff0000 0%, #cd0000 100%) 0% 0% no-repeat
    padding-box;
  font-weight: 400;
  font-size: 1.0625rem;
  line-height: 1.0625rem;
  padding: 0.125rem 0.5625rem;
  border-radius: 1.875rem;
}
.wrap-white-content {
  @apply bg-white flex flex-col px-5 py-6;
  border-radius: 0.625rem;
}
.ant-drawer .ant-drawer-close {
  font-size: 1.25rem;
  color: #707070;
}

.modal-order-success .ant-modal-title {
  @apply text-36 leading-36 text-primary font-medium;
  text-align: center;
}
.modal-order-success .ant-modal-body {
  @apply flex flex-col px-0 sm:px-3;
  min-width: auto;
}
@media (min-width: 640px) {
  .modal-order-success .ant-modal-body {
    min-width: max-content;
  }
}
.modal-order-success .ant-modal-body .wrap-detial {
  @apply flex flex-row items-start sm:items-center;
}
.modal-order-success .ant-modal-footer {
  @apply flex flex-col gap-4;
  margin-top: 0.625rem;
}
.modal-order-success .ant-modal-footer button.ant-btn-default {
  @apply order-2;
}
.modal-order-success .ant-modal-footer button.ant-btn-primary {
  @apply order-1;
}

.container-card-profile {
  @apply flex flex-col gap-4;
  width: calc(100% - 17.1875rem);
  min-height: 50dvh;
}
@media (max-width: 1280px) {
  .container-card-profile {
    width: 100%;
    /* display: none; */
  }
}

/* Address */

.box-content-border-gray {
  @apply flex flex-col p-0 sm:p-4 bg-white;
  border-radius: 0;
  border: 0;
}
@media (min-width: 640px) {
  .box-content-border-gray {
    @apply flex flex-col p-4;
    border-radius: 0.5rem;
    border: 1px solid #e3e3e3;
  }
}

.input-border-10 .ant-input {
  border-radius: 0.625rem !important;
}

.container-border {
  @apply bg-white flex flex-col gap-2 w-full justify-end px-4 py-4 md:px-5 md:py-7;
  border-radius: 0.625rem;
}
.warp-border {
  @apply bg-white flex flex-col gap-2 w-full justify-end p-2;
  border-radius: 0.625rem;
  border: 1px solid #e3e3e3;
  width: 100%;
}
.w-button-link {
  @apply flex flex-row items-center justify-center;
  width: 100%;
}
@media (min-width: 640px) {
  .w-button-link {
    width: max-content;
  }
}

.ant-picker-cell-inner {
  min-width: 30px !important;
  height: 30px !important;
  line-height: 30px !important;
  border-radius: 50% !important;
}
.ant-picker-cell-inner::before {
  border-radius: 50% !important;
}

.cm-datepicker-upload {
  @apply text-dark-gray;
  padding: 0 !important;
  font-size: 1.5rem !important;
  line-height: 1.5rem !important;
  border: 0 !important;
}
.cm-datepicker-upload .ant-picker-input input::placeholder {
  @apply text-dark-gray;
  font-size: 1.5rem !important;
  line-height: 1.5rem !important;
}
.cm-datepicker-upload .ant-picker-input input:focus {
  box-shadow: none !important;
  outline: none !important;
  border: 0 !important;
}
.cm-datepicker-upload.ant-picker-focused {
  box-shadow: none !important;
}
.cm-datepicker-upload .ant-picker-input input {
  @apply text-dark-gray;
  font-size: 1.5rem !important;
  line-height: 1.5rem !important;
  min-width: 6.25rem !important;
  max-width: 6.25rem !important;
}
.ant-picker-now-btn {
  color: #ff0000 !important;
}

.cm-datepicker-form {
  @apply text-dark-gray bg-gray-f2;
  padding: 0.35rem 1.125rem;
  width: 100%;
  border-radius: 1.875rem;
}
.cm-datepicker-form .ant-picker-input {
  display: flex;
  justify-content: space-between;
}
.cm-datepicker-form .ant-picker-input input::placeholder {
  color: #9a9a9a;
  font-size: 1.125rem !important;
  line-height: 1.125rem !important;
}
.cm-datepicker-form .ant-picker-input input:focus {
  box-shadow: none !important;
  outline: none !important;
  border: 0 !important;
}
.cm-datepicker-form.ant-picker-focused {
  box-shadow: none !important;
  border-color: #d9d9d9 !important;
}
.cm-datepicker-form .ant-picker-input input {
  @apply text-dark-gray;
  font-size: 1.5rem !important;
  line-height: 1.5rem !important;
  min-width: 6.25rem !important;
  max-width: 6.25rem !important;
}
.ant-picker-dropdown .ant-picker-content th,
.ant-picker-dropdown .ant-picker-content td {
  min-height: auto !important;
  padding: 0 !important;
}
.ant-picker-header-view button {
  color: #ff0000 !important;
}
.ant-picker-ok button {
  background-color: #ff0000 !important;
}
.ant-picker-content thead {
  background-color: #feeaea !important;
  border-radius: 10px !important;
}
.ant-picker-content thead tr {
  border-radius: 10px !important;
}

.ant-picker-content thead tr th:first-child {
  border-radius: 10px 0 0 10px !important;
}
.ant-picker-content thead tr th:last-child {
  border-radius: 0 10px 10px 0 !important;
}

/* .box-icon-success {
  @apply bg-green-success;
  max-width: 6.25rem;
  min-width: 6.25rem;
  max-height: 6.25rem;
  min-height: 6.25rem;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
} */

.foot-order {
  @apply flex flex-col sm:flex-row justify-start sm:justify-between items-start sm:items-center;
}
.foot-order .item-start {
  @apply w-full sm:w-auto flex flex-col sm:flex-row items-end sm:items-center sm:gap-0 gap-3 py-3;
}
.foot-order .item-start .text-detail {
  @apply text-dark-gray;
  font-size: 1.125rem;
  line-height: 1.125rem;
}
.foot-order .item-start .text-detail span {
  font-size: 1.5rem;
  line-height: 1.5rem;
}
.foot-order .item-start .text-detail {
  display: flex;
  align-items: center;
}
.foot-order .item-start .text-detail::after {
  display: none;
}
@media (min-width: 640px) {
  .foot-order .item-start .text-detail::after {
    display: inline-block;
    height: 16px;
    content: ' ';
    border: 0.0625rem solid #8f8f8f;
    margin: 0 10px;
  }
  .foot-order .item-start .text-detail:last-child::after {
    display: none;
  }
}
.foot-order .item-end {
  @apply flex flex-row items-end;
}
.foot-order .item-end .text-detail {
  @apply text-primary;
  font-size: 1.5rem;
  line-height: 1.5rem;
}
.foot-order .item-end .text-detail span {
  @apply pl-2;
  font-size: 2.25rem;
  line-height: 2.25rem;
}
.foot-order-btn {
  @apply flex flex-col sm:flex-row justify-end;
}
.foot-order-btn .btn {
  width: 100%;
}
@media (min-width: 640px) {
  .foot-order-btn .btn {
    width: 12.6875rem;
  }
}
.label-cm-form {
  @apply text-dark-gray;
  font-size: 1.5rem;
  line-height: 1.5rem;
  padding-bottom: 0.3125rem;
}
.cm-form .ant-form-item {
  width: 100%;
  margin-bottom: 0 !important;
}

/* INPUT */
.cm-form .ant-form-item .ant-input {
  @apply text-dark-gray bg-gray-f2;
  font-size: 1.125rem;
  line-height: 1.125rem;
}
.cm-form textarea {
  border-radius: 0.625rem;
}
.cm-form .ant-form-item .ant-input:hover {
  border-color: #ff0000;
  background-color: #ffffff;
}
.cm-form .ant-form-item .ant-input:focus {
  border-color: #ff0000;
  background-color: #ffffff;
}
.cm-form .ant-form-item .ant-input::placeholder {
  color: #9a9a9a;
}

/* INPUTNUMBER */
.cm-form .ant-form-item .ant-input-number {
  border-radius: 30px;
  width: 100%;
}
.cm-form.disable-handler .ant-input-number-handler-wrap {
  display: none !important;
}
.cm-form .ant-form-item .ant-input-number {
  @apply text-dark-gray bg-gray-f2;
  font-size: 1.125rem;
  line-height: 1.125rem;
  padding: 0.5rem 1.5625rem;

  width: 100%;
}
.cm-form .ant-form-item .ant-input-number .ant-input-number-input {
  padding: 0;
  height: auto;
}
.cm-form textarea {
  border-radius: 0.625rem;
}
.cm-form .ant-form-item .ant-input-number:hover {
  border-color: #ff0000;
  background-color: #ffffff;
  box-shadow: none;
}
.cm-form .ant-form-item .ant-input-number:focus {
  border-color: #ff0000;
  background-color: #ffffff;
  box-shadow: none;
}
.cm-form .ant-form-item .ant-input-number .ant-input-number-input::placeholder {
  color: #9a9a9a;
}

/* SELECT */
.cm-form .ant-form-item .ant-select-selector {
  @apply text-dark-gray bg-gray-f2;
  font-size: 1.125rem;
  line-height: 1.125rem;
  padding: 0.5rem 1.125rem;
  height: 38px;
}
.cm-form .ant-form-item .ant-select-selector:hover {
  border-color: #ff0000 !important;
  background-color: #ffffff;
  box-shadow: none;
}
.cm-form .ant-form-item .ant-select-selector:focus {
  border-color: #ff0000 !important;
  background-color: #ffffff;
  box-shadow: none;
}
.cm-form .ant-form-item .ant-select-selector .ant-select-selection-search-input {
  height: auto;
}
.cm-form .ant-select-selector .ant-select-selection-placeholder {
  line-height: 23px;
  color: #9a9a9a;
}
.cm-form .ant-select-selector .ant-select-selection-item {
  line-height: 1.4375rem;
}
.ant-skeleton {
  width: 100% !important;
  height: 100% !important;
}
.w-skeleton {
  width: 100% !important;
  height: 100% !important;
}

.row-button-back a {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  margin-bottom: 30px;
}
.button-back {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  max-width: 2.125rem;
  max-height: 2.125rem;
  min-width: 2.125rem;
  min-height: 2.125rem;
  border-radius: 50%;
  background-color: #ffffff;
  box-shadow: 0px 3px 6px #00000029;
}
.icon-back-color {
  color: #5a5a5a;
}
#tidio-chat-iframe {
  bottom: 40px !important;
}
